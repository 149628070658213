import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// "Inline" English and Arabic translations.
// We can localize to any language and any number of languages.
const resources = {
  en: {
    translation: {
      data_visualization: 'Data Visualization',
      intro_text:
        'This application uses open source libraries to collect and display calibrated satellite data and compare them with in situ rainfall stations operated by ANA (Agência Nacional de Águas) in Brazil. Shows 20-year satellite precipitation measurements of more than 1500 pluviometric and fluviometric points of interest in Brazil',
      satellite: 'Satellite',
      intro_ana:
        'This application uses precipitation data provided by ANA (National Water Agency). Hydrometeorological stations are operated by partner entities or contracted by ANA, which is responsible for planning, standardizing procedures and equipment, inspection, data organization hydrometeorological surveys and their publication.',
      details_ana:
        "More details about ANA's Catalog and Repository can be seen at the following link",
      hydrological_network: 'National Hydrometeorological Network',
      pluviometric_stations: 'Pluviometric Stations',
      constellation_gpm: 'Product IMERGE INPE - GPM',
      intro_sat:
        'This application uses precipitation data provided by the Center for Weather Forecasting and Climate Studies (CPTEC), a division of the National Institute for Space Research (INPE). The MERGE product has been generated using the Global Precipitation Measurement (GPM) Integrated Multi-satellite Retrievals for GPM (IMERG) data. More details on the technique can be found at',
      details_sat:
        "NASA's Global Precipitation Measurement Mission (GPM) uses satellites to measure Earth's rain and snowfall for the benefit of humanity. Launched by NASA and JAXA on February 27, 2014, the GPM is an international mission that sets the standard for measurements of precipitation in space. Using a network of satellites joined by the GPM Core Observatory, the GPM expands on the legacy of the Tropical Rainfall Measuring Mission (TRMM) by providing high-quality estimates of Earth's rain.",
      close: 'Close',
      english: 'English',
      french: 'French',
      portuguese: 'Portuguese',
      spanish: 'Spanish',
      type: 'Type',
      rain_data: 'Get Rain Data',
      code: 'Code',
      responsible: 'Responsible',
      operator: 'Operator',
      city: 'City',
      scope: 'Scope',
      river: 'River',
      escale: 'Escale',
      escale_init: 'Escale Reg.',
      evaporimeter: 'Evaporimeter',
      climatological: 'Climatological',
      telemetry: 'Telemetry',
      sediments: 'Sediments',
      water_quality: 'Water Quality',
      liquid_discharge: 'Liquid Discharge',
      yes: 'Yes',
      no: 'No',
      city_state: 'City/State',
      longitude_latitude: 'Longitude/Latitude',
      longitude: 'Longitude',
      latitude: 'Latiude',
      country_state: 'State',
      country: 'Country',
      reg_fail: 'Days/Months Fails',
      no_local_data: ' No data from local(in situ) station',
      no_sat_data: 'Type',
      precipitation: 'Total Rainfall (mm)/Month',
      prec_details: 'ANA Repository',
      prec_details2: 'Satellites GPM',
      prec_overall: "Year's Average",
      prec_satOnly: 'No in situ precipitation data!',
      prec: 'Precipitation',
      daily: 'Daily',
      about_imerge: 'About IMERGE',
      monthly: 'Monthly',
      name: 'Name',
      pluviometric: 'Pluviometric',
      fluviometric: 'Fluviometric',
      basin_info: 'Watershed Information',
      Jan: 'Jan',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      May: 'May',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Aug',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dec',
      year: 'Year',
      img: 'en',
      details: 'Details',
      about_app: 'About this App',
      instructions: 'Instructions',
      init01:
        'This application shows historical precipitation series from the National Hydrometereological Network (RNH) coordinated by ANA (National Water Agency). The data from the rainfall stations in this application are operated by the SGB (Brazilian Geological Survey)',
      init02:
        '- Access the rainfall stations of the National Hydrometereological Network, by clicking on the circles (Clusters) and the station icons.',
      init03:
        '- View monthly and daily precipitation by clicking on the tabs: Monthly and Daily, when opening the seasons modal.',
      init04:
        "- The monthly average rainfall in millimeters at the station can be compared with the precipitation measured by satellite at the station's 10km2 'pixel'. The station's in situ monthly average is in purple. In green is the precipitation measured by satellite.",
      init05:
        '- The precipitation year can be selected for in situ and satellite by clicking on the bottom of the modal, dropdown menu.',
      init06: '- The day of the maximum month is in parentheses in the Daily precipitation tab.',
      app_libraries: 'Open source Libraries in this App',
      option_language: 'Language',
      option_update: 'Update',
      application_update: 'Application Update',
      profile: 'Profile',
      user_details: 'User Details',
      app_settings: 'MapHidro Settings',
      home: 'Home',
      map: 'Map',
      about: 'About',
      uxsurvey: 'UX(Survey)',
      logout: 'Logout',
      projects: 'Projects',
      timeline: 'Timeline',
      download: 'Download',
      select: 'Select',
      filters: 'Filters',
      results: 'Results',
      help: 'Help',
      settings: 'Settings',
      select_layers: 'Select Layers',
      map_layers: 'Map Layers',
      brazil_basins: 'Brazil Basins',
      main_rivers: 'Main Rivers',
      samerica_basins: 'S. America Basins',
      heatmap: 'Heatmap',
      maphidro_help: 'MapHidro Help',
      usage_instructions: 'Usage Instructions',
      download_data: 'Download Data',
      data_patern: 'Data Patern',
      satelite_data: 'Satellite Data',
      about_json: 'About JSON Format',
      language: 'Language',
      uxpage_title: 'User Experience Questionary',
      where: 'where',
      when: 'when',
      how: 'how',
      about_maphidro: 'About MapHidro',
      copyrights: 'All rights reserved',
      submit: 'Submit',
      about1:
        'This application uses open source libraries to collect and display calibrated satellite data and compare them with in situ rainfall stations operated by CRPM(Geological Survey of Brazil) from the National Hydrometeorological Network (RHN) coordinated by ANA (National Water and Sanitation Agency) in Brazil. Shows 20-year satellite precipitation measurements of more than 1500 pluviometric points of interest in Brazil',
      about2:
        'The Satellite precipitation data is provided by the Center for Weather Forecasting and Climate Studies (CPTEC), a division of the National Institute for Space Research (INPE). The MERGE product has been generated using the Global Precipitation Measurement (GPM) Integrated Multi-satellite Retrievals for GPM (IMERG) data. More details on the technique can be found at',
      about3:
        "NASA's Global Precipitation Measurement Mission (GPM) uses several satellites to measure Earth's rain and snowfall for the benefit of humanity. GPM is an international mission launched by NASA and JAXA(Japan) that sets the standard for measurements of precipitation in space.",
      indevelopment: 'This application is under development',
      uab_text:
        'Dissertation project for MSc in Informatics Engineering and Web Technology UAB/UTAD',
      search_for: 'Search for stations',
      search: 'search...',
      mapaccess: 'Acesse o mapa clicando aqui',
      series: 'Historical Series - Precipitation (mm)',
      select_years: 'Select the year of precipitation below',
      click_download: 'Click on the buttons to download data',
      thanks_response: 'Thank you for your response',
      survey_text01:
        'Please give us your opinion, in order to evaluate the maphidro.com portal, by filling out the following questionnaire below. It is made up of pairs of opposites relating to the properties that the product may have. Graduations between opposites are represented by circles. By checking one of the circles, you can express your opinion about a concept. The questionnaire is anonymous, there are no "right" or "wrong" answers. Only your personal opinion counts!',
      signin: 'SIGN IN',
      signup: 'Sign up for MapHidro',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      register_access: 'Register to access',
      home_text_01:
        'Dissertation project for MSc in Informatics Engineering and Web Technology UAB/UTAD',
      home_text_02: 'MapHidro: Historical Series Precipitation in situ/satellite',
      create_username: 'Create your username',
      create_password: 'Create a password',
      email_placeholder: 'you@example.com',
      user_alreadyregistered: 'That username is already taken',
      valid_email: 'You must provide a valid email',
      password_min: 'Password must be at least 5',
      please_register: 'Please register to access the map functionality.',
      visit_homepage: 'You can visit the this link to sign up: ',
      homepage: 'HomePage'
    }
  },
  pt: {
    translation: {
      data_visualization: 'Visualização de Dados',
      intro_text:
        'Este aplicativo usa bibliotecas de código aberto para coletar e exibir dados calibrados de satelite e compará-los com estações pluviométricas in situ operadas pela ANA (Agência Nacional de Águas) no Brasil. Mostra medições de precipitação de satélites de 20 anos de mais de 1500 pontos de interesse pluviométricos e fluviométricos no Brasil.',
      satellite: 'Satélite',
      intro_ana:
        'Este aplicativo utiliza-se de dados de precipitação disponibilizados pela ANA (Agência Nacional de Águas). As estações hidrometeorológicas são operadas por entidades parceiras ou contratadas pela ANA, que é a responsável pelo planejamento, normatização de procedimentos e equipamentos, fiscalização, organização dos dados hidrometeorológicos e sua publicação.',
      details_ana:
        'Mais detalhes sobre o Catálogo e Repositório da ANA podem ser vistos no seguinte link',
      hydrological_network: 'Rede Hidrometeorológica Nacional',
      pluviometric_stations: 'Estações Pluviométricas',
      constellation_gpm: 'Produto IMERGE INPE - GPM',
      intro_sat:
        'Este aplicativo utiliza-se de dados de precipitação disponibilizado pelo Centro de Previsão de Tempo e Estudos Climáticos (CPTEC), uma divisão do Instituto Nacional de Pesquisas Espaciais (INPE). O produto MERGE vem sendo gerado utilizando os dados do Global Precipitation Measurement (GPM) Integrated Multi-satellitE Retrievals for GPM (IMERG). Mais detalhes sobre a técnica podem ser encontrados em',
      details_sat:
        'A Missão Global de Medição de Precipitação (GPM) da NASA usa satélites para medir a chuva e a queda de neve da Terra para o benefício da humanidade. Lançado pela NASA e JAXA em 27 de fevereiro de 2014, o GPM é uma missão internacional que define o padrão para medições de precipitação no espaço. Usando uma rede de satélites unidos pelo GPM Core Observatory.',
      close: 'Fechar',
      english: 'Inglês',
      french: 'Francês',
      portuguese: 'Português',
      spanish: 'Espanhol',
      type: 'Tipo',
      rain_data: 'Obter Dados de Chuva',
      code: 'Código',
      responsible: 'Responsável',
      operator: 'Operador',
      city: 'Município',
      scope: 'Trecho',
      river: 'Rio',
      escale: 'Escala',
      escale_init: 'Escala Ini.',
      evaporimeter: 'Evaporimetro',
      climatological: 'Climatologica',
      telemetry: 'Telemetria',
      sediments: 'Sedimentos',
      water_quality: 'Qual. da Água',
      liquid_discharge: 'Decarga Liqui.',
      yes: 'Sim',
      no: 'Não',
      city_state: 'Município/Estadoe',
      longitude_latitude: 'Longitude/Latitude',
      longitude: 'Longitude',
      latitude: 'Latiude',
      country_state: 'Estado',
      country: 'País',
      reg_fail: 'Dias/Meses Falhas',
      no_local_data: ' Sem dados estação local(in situ)',
      no_sat_data: 'Sem dados de satélite',
      precipitation: 'Precipitação Total (mm)/mês',
      prec_details: 'Repositório ANA',
      prec_details2: 'Satélites GPM',
      prec_overall: 'Média dos Anos',
      prec_satOnly: 'Sem dados de precipitação in situ!',
      prec: 'Precipitação',
      daily: 'Diário',
      about_imerge: 'Sobre IMERGE',
      monthly: 'Mensal',
      name: 'Nome',
      pluviometric: 'Pluviométrica',
      fluviometric: 'Fluviométrica',
      basin_info: 'Informações da Bacia',
      Jan: 'Jan',
      Feb: 'Fev',
      Mar: 'Mar',
      Apr: 'Abr',
      May: 'Mai',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Ago',
      Sep: 'Set',
      Oct: 'Out',
      Nov: 'Nov',
      Dec: 'Dez',
      year: 'Ano',
      img: 'pt',
      details: 'Detalhes',
      about_app: 'Sobre este App',
      instructions: 'Instruções',
      init01:
        'Esta aplicação mostra séries históricas de precipitação da Rede Hidrometereológica Nacional(RNH) coordenadas pela ANA(Agência Nacional de Águas). Os dados das estações pluviométricas neste aplicativos são operadas pelo SGB(Serviço Geológico do Brasil)',
      init02:
        '- Acesse as estações pluviométricas das Rede Hidrometereológica Nacional, clicando nos círculos(Clusters) e nos ícones das estacões.',
      init03:
        '- Visualize precipitação mensal e diária clicando nas abas: Mensal e Diária, ao abrir o modal das estações.',
      init04:
        "- A média mensal em milímetros de chuva da estação pode ser comparada com a precipitação medida por satélite no 'pixel' de 10km2 da estação. A média mensal in situ da estação está em roxo. Em verde está a precipitação medida por satélite.",
      init05:
        '- O ano de precipitação pode ser selecionado para in situ e satélite clicando na parte inferior do modal, menu dropdown.',
      init06: '- O dia do mês das máximas estão entre parenteses na aba de precipitação Diário.',
      app_libraries: 'Bibliotecas de código aberto neste aplicativo',
      option_language: 'Idioma',
      option_update: 'Atualizar',
      application_update: 'Atualizar aplicativo',
      profile: 'Perfil',
      user_profile: 'Perfil de Utilizador',
      user_details: 'Detalhes de Usuário',
      app_settings: 'MapHidro Configurações',
      home: 'Home',
      map: 'Mapa',
      about: 'Sobre',
      uxsurvey: 'UX(Questionário)',
      logout: 'Sair',
      projects: 'Projetos',
      timeline: 'Linha de Tempo',
      download: 'Descarregar',
      select: 'Selecionar',
      filters: 'Filtros',
      results: 'Resultados',
      help: 'Ajuda',
      settings: 'Configurações',
      select_layers: 'Selecionar Camadas',
      map_layers: 'Camadas Mapa',
      brazil_basins: 'Bacias Brasil',
      main_rivers: 'Rios Principais',
      samerica_basins: 'Bacias A. do Sul',
      heatmap: 'Mapa de Calor',
      maphidro_help: 'MapHidro Ajuda',
      usage_instructions: 'Instruções de uso',
      download_data: 'Descarregar Dados',
      data_patern: 'Padrões de Dados',
      satelite_data: 'Dados de Satélite',
      about_json: 'Sobre o Formato JSON',
      language: 'Idioma',
      uxpage_title: 'Questionário de UX',
      where: 'onde',
      when: 'quando',
      how: 'como',
      about_maphidro: 'Sobre MapHidro',
      copyrights: 'Todos os direitos reservados',
      submit: 'Enviar',
      about1:
        'Este é um projeto de aplicação que resulta da dissertação de mestrado em Engenharia Informática e Tecnologia Web da UAB(Universidade Aberta) e da UTAD(Universidade de Trás-os-Montes e Alto Douro)',
      about2:
        'Utiliza bibliotecas de código aberto para coletar e exibir dados de satélite calibrados da NASA Global Precipitation Measurement (GPM) e compará-los com estações pluviométricas in situ operadas pelo CRPM (Serviço Geológico do Brasil) da Rede Hidrometeorológica Nacional (RHN) coordenada pela ANA (National Agência de Águas e Saneamento) no Brasil. O produto de satélite foi gerado usando dados de precipitação GPM (IMERG). Mais detalhes sobre a técnica podem ser encontrados em',
      about3:
        'Mostra medições de precipitação por satélite de 20 anos de mais de 1.500 pontos pluviométricos de interesse no Brasil.',
      indevelopment: 'Este aplicativo está em desenvolvimento',
      uab_text:
        'Projeto de dissertação para Mestrado em Engenharia Informática e Tecnologia Web UAB/UTAD',
      search_for: 'Busca por estações',
      search: 'busca...',
      mapaccess: 'Acesse o mapa clicando aqui',
      series: 'Séries Históricas - Precipitação(mm)',
      select_years: 'Selecione o ano de precipitação abaixo',
      click_download: 'Clique nos botões para descarregar',
      thanks_response: 'Obrigado por sua resposta',
      survey_text01:
        'Por favor dê-nos a sua opinião, a fim de avaliar o portal maphidro.com, preenchendo o seguinte questionário abaixo. É constituído por pares de opostos relativos às propriedades que o produto possa ter. As graduações entre os opostos são representadas por círculos. Ao marcar um dos círculos, você pode expressar sua opinião sobre um conceito. O questionário é anômimo, não existem respostas "certas" ou "erradas". Só a sua opinião pessoal conta!',
      signin: 'Entrar',
      signup: 'Cadastre-se no MapHidro',
      username: 'Nome de Usuário',
      email: 'Email',
      password: 'Senha',
      register_access: 'Registre-se para acessar',
      home_text_01:
        'Projeto de dissertação para Mestrado em Engenharia Informática e Tecnologia Web UAB/UTAD',
      home_text_02: 'MapHidro: Séries Históricas de Precipitação in situ/satélite',
      create_username: 'Crie seu nome de usuário',
      create_password: 'Crie sua senha',
      email_placeholder: 'voce@exemplo.com',
      user_alreadyregistered: 'Esse nome de usuário já está em uso',
      valid_email: 'Você deve inserir um e-mail valido',
      password_min: 'A senha deve ser pelo menos 5',
      please_register: 'Por favor registre-se para acessar a funcionalidade do mapa.',
      visit_homepage: 'Você pode visitar este link para registrar-se: ',
      homepage: 'Página inicial'
    }
  },
  fr: {
    translation: {
      data_visualization: 'Visualisation de données',
      intro_text:
        "Cette application utilise des bibliothèques open source pour collecter et afficher les données calibrées IMERGE-INPE (Institut national de recherche spatiale) de la mission GPM et pour les comparer avec stations pluviométriques in situ exploitées par l'ANA (Agence nationale de l'eau) au Brésil. Affiche les mesures de précipitations satellites sur 20 ans (+7.500 par jour) de chacun des +16.000 points d'intérêt pluviométriques et fluviométriques au Brésil",
      satellite: 'Satellite',
      intro_ana:
        "Cette application utilise les données de précipitations fournies par l'ANA (Agence nationale de l'eau). Les stations hydrométéorologiques sont exploitées par des entités partenaires ou contractées par l'ANA, qui est responsable de la planification, de la normalisation des procédures et des équipements, de l'inspection, de l'organisation des données des levés hydrométéorologiques et de leur publication.",
      details_ana:
        'Plus de détails sur le catalogue et le référentiel ANA peuvent être consultés sur le lien suivant',
      hydrological_network: 'Réseau hydrométéorologique national',
      pluviometric_stations: 'Stations Pluviométriques',
      constellation_gpm: 'Produit IMERGE INPE - GPM',
      intro_sat:
        "Cette application utilise les données de précipitations fournies par le Centre de Prévision Météorologique et d'Etudes Climatiques (CPTEC), une division de l'Institut National de Recherche Spatiale (INPE). Le produit MERGE a été généré à l'aide des données GPM (Global Precipitation Measurement) Integrated Multi-satellite Retrievals for GPM (IMERG). Plus de détails sur la technique peuvent être trouvés à",
      details_sat:
        "La mission mondiale de mesure des précipitations (GPM) de la NASA utilise des satellites pour mesurer la pluie et les chutes de neige de la Terre au profit de l'humanité. Lancé par la NASA et la JAXA le 27 février 2014, le GPM est une mission internationale qui établit la norme pour les mesures des précipitations dans l'espace. Utilisant un réseau de satellites rejoint par le GPM Core Observatory.",
      close: 'Fermer',
      english: 'Anglais',
      french: 'Français',
      portuguese: 'Portugais',
      spanish: 'Espagnol',
      type: 'Taper',
      rain_data: ' Obtenir des données de pluie',
      code: 'Code',
      responsible: 'Responsable',
      operator: 'Opérateur',
      city: 'Zone municipale',
      scope: 'Portée',
      river: 'Rivièree',
      escale: "Registre d'échelle",
      escale_init: 'Échelle ini.',
      evaporimeter: 'Évaporimètre',
      climatological: 'climatologique',
      telemetry: 'Télémétrie',
      sediments: 'Sédiments',
      water_quality: "Qualification d'Água",
      liquid_discharge: 'Décharger liquide',
      yes: 'Oui',
      no: 'Non',
      city_state: 'Zone municipale/Etat',
      longitude_latitude: 'Longitude/Latitude',
      longitude: 'Longitude',
      latitude: 'Latiude',
      country_state: 'Etat',
      country: 'Pays',
      reg_fail: 'Jours/Mois-Échec',
      no_local_data: ' Aucune donnée de station locale (in situ)',
      no_sat_data: 'Aucune donnée du satellite',
      precipitation: 'Précipitations totales (mm)/mois',
      prec_details: 'référentiel ANA',
      prec_details2: 'Satellites GPM',
      prec_overall: 'Moyenne des années',
      prec_satOnly: 'Pas de données pluviométriques in situ!',
      prec: 'Précipitation',
      daily: 'Quotidien',
      about_imerge: "À propos d'IMERGE",
      monthly: 'Mensuel',
      name: 'Nom',
      pluviometric: 'Pluviométrique',
      fluviometric: 'Fluviométrique',
      basin_info: 'Informations sur le bassin',
      Jan: 'Janv',
      Feb: 'Févr',
      Mar: 'Mars',
      Apr: 'Avr',
      May: 'Mai',
      Jun: 'Juin',
      Jul: 'Juil',
      Aug: 'Août',
      Sep: 'Sept',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Déc',
      year: 'An',
      img: 'fr',
      details: 'Détails',
      about_app: 'à propos de cette App',
      instructions: 'Instructions',
      init01:
        "Cette application montre les séries historiques de précipitations du Réseau Hydrométéorologique National (RNH) coordonné par l'ANA (Agence Nationale de l'Eau). Les données des stations pluviométriques de cette application sont exploitées par le SGB (Brazilian Geological Survey)",
      init02:
        '- Accédez aux stations pluviométriques du Réseau Hydrométéorologique National, en cliquant sur les cercles (Clusters) et les icônes des stations.',
      init03:
        "- Visualisez les précipitations mensuelles et quotidiennes en cliquant sur les onglets : Mensuel et Quotidien, lors de l'ouverture du modal des saisons.",
      init04:
        "- Les précipitations moyennes mensuelles en millimètres à la station peuvent être comparées aux précipitations mesurées par satellite au 'pixel' de 10 km2 de la station. La moyenne mensuelle in situ de la station est en violet. En vert se trouvent les précipitations mesurées par satellite.",
      init05:
        "- L'année de précipitation peut être sélectionnée pour in situ et par satellite en cliquant en bas du menu déroulant modal.",
      init06:
        "- Le jour du mois maximum est entre parenthèses dans l'onglet Précipitations quotidiennes.",
      app_libraries: 'Bibliothèques open source dans cette application',
      option_language: 'Language',
      option_update: 'Update',
      application_update: 'Application Update',
      profile: 'Profile',
      user_details: 'User Details',
      app_settings: 'MapHidro Settings',
      home: 'Home',
      map: 'Map',
      about: 'About',
      uxsurvey: 'UX(Survey)',
      logout: 'Logout',
      projects: 'Projects',
      timeline: 'Timeline',
      download: 'Download',
      select: 'Select',
      filters: 'Filters',
      results: 'Results',
      help: 'Help',
      settings: 'Settings',
      select_layers: 'Select Layers',
      map_layers: 'Map Layers',
      brazil_basins: 'Brazil Basins',
      main_rivers: 'Main Rivers',
      samerica_basins: 'S. America Basins',
      heatmap: 'Heatmap',
      maphidro_help: 'MapHidro Help',
      usage_instructions: 'Usage Instructions',
      download_data: 'Download Data',
      data_patern: 'Data Patern',
      satelite_data: 'Satellite Data',
      about_json: 'About JSON Format',
      language: 'Language',
      uxpage_title: 'Questionário de UX',
      where: 'where',
      when: 'when',
      how: 'how',
      about_maphidro: 'About MapHidro',
      copyrights: 'All rights reserved',
      submit: 'Submit',
      about1:
        'This application uses open source libraries to collect and display calibrated satellite data and compare them with in situ rainfall stations operated by CRPM(Geological Survey of Brazil) from the National Hydrometeorological Network (RHN) coordinated by ANA (National Water and Sanitation Agency) in Brazil. Shows 20-year satellite precipitation measurements of more than 1500 pluviometric points of interest in Brazil',
      about2:
        'The Satellite precipitation data is provided by the Center for Weather Forecasting and Climate Studies (CPTEC), a division of the National Institute for Space Research (INPE). The MERGE product has been generated using the Global Precipitation Measurement (GPM) Integrated Multi-satellite Retrievals for GPM (IMERG) data. More details on the technique can be found at',
      about3:
        "NASA's Global Precipitation Measurement Mission (GPM) uses several satellites to measure Earth's rain and snowfall for the benefit of humanity. GPM is an international mission launched by NASA and JAXA(Japan) that sets the standard for measurements of precipitation in space.",
      indevelopment: 'This application is under development',
      uab_text:
        'Dissertation project for MSc in Informatics Engineering and Web Technology UAB/UTAD',
      search_for: 'Search for stations',
      search: 'search...',
      mapaccess: 'Acesse o mapa clicando aqui',
      series: 'Historical Series - Precipitation (mm)',
      select_years: 'Select the year of precipitation below',
      click_download: 'Click on the buttons to download data',
      thanks_response: 'Merci pour votre réponse',
      survey_text01:
        "Merci de nous donner votre avis, afin d'évaluer le portail maphidro.com, en remplissant le questionnaire suivant ci-dessous. Il est constitué de couples d’opposés relatifs aux propriétés que peut avoir le produit. Les graduations entre les opposés sont représentées par des cercles. En cochant l'un des cercles, vous pouvez exprimer votre opinion sur un concept. Le questionnaire est anonyme, il n’y a pas de « bonnes » ou de « mauvaises » réponses. Seul votre avis personnel compte !",
      signin: 'SE CONNECTER',
      signup: 'Inscrivez-vous à MapHidro',
      username: "Nom d'utilisateur",
      email: 'Email',
      password: 'Mot de passe',
      register_access: 'Inscrivez-vous pour accéder',
      home_text_01:
        'Projet de thèse pour MSc en Ingénierie Informatique et Technologie Web UAB/UTAD',
      home_text_02: 'MapHidro : Série historique Précipitations in situ/satellite',
      create_username: "Créez votre nom d'utilisateur",
      create_password: 'Créer un mot de passe',
      email_placeholder: 'vous@exemple.com',
      user_alreadyregistered: "Ce nom d'utilisateur est déjà pris",
      valid_email: 'vous devez fournir une adresse email valide',
      password_min: 'Le mot de passe doit être au moins 5',
      please_register: 'Please register to access the map functionality.',
      visit_homepage: 'You can visit the this link to sign up: ',
      homepage: 'HomePage'
    }
  },
  es: {
    translation: {
      data_visualization: 'Visualización de datos',
      intro_text:
        'Esta aplicación utiliza bibliotecas de código abierto para recopilar y mostrar datos calibrados del IMERGE-INPE (Instituto Nacional de Investigaciones Espaciales) de la Misión GPM y compararlos con estaciones pluviométricas in situ operadas por ANA (La Agencia Nacional del Agua) en Brasil. Muestra mediciones satelitales de precipitación de 20 años (+7.500 diarios) de cada uno de los +16.000 puntos de interés pluviométricos y fluviométricos en Brasil.',
      satellite: 'Satélite',
      intro_ana:
        'Esta aplicación utiliza datos de precipitación proporcionados por ANA (Agencia Nacional del Agua). Las estaciones hidrometeorológicas son operadas por entidades colaboradoras o contratadas por la ANA, la cual se encarga de la planificación, estandarización de procedimientos y equipos, inspección, organización de los datos de los levantamientos hidrometeorológicos y su publicación.',
      details_ana:
        'Se pueden ver más detalles sobre el Catálogo y Repositorio de ANA en el siguiente enlace',
      hydrological_network: 'Red Hidrometeorológica Nacional',
      pluviometric_stations: 'Estaciones pluviométricas',
      constellation_gpm: 'Producto IMERGE INPE - GPM',
      intro_sat:
        'Esta aplicación utiliza datos de precipitación proporcionados por el Centro de Pronóstico del Tiempo y Estudios Climáticos (CPTEC), una división del Instituto Nacional de Investigaciones Espaciales (INPE). El producto MERGE se ha generado utilizando las recuperaciones multisatélite integradas de medición de precipitación global (GPM) para datos GPM (IMERG). Más detalles sobre la técnica se pueden encontrar en',
      details_sat:
        'La Misión de Medición de Precipitaciones Globales (GPM) de la NASA utiliza satélites para medir la lluvia y las nevadas de la Tierra en beneficio de la humanidad. Lanzado por la NASA y JAXA el 27 de febrero de 2014, el GPM es una misión internacional que establece el estándar para las mediciones de precipitación en el espacio. Usando una red de satélites unidos por el Observatorio GPM Core.',
      close: 'Cerrar',
      english: 'Inglés',
      french: 'Francés',
      portuguese: 'Portugués',
      spanish: 'Español',
      type: 'Tipo',
      rain_data: '  Obtener datos de lluvia',
      code: 'Código',
      responsible: 'Responsable',
      operator: 'Operador',
      city: ' Área municipal',
      scope: 'Ámbito',
      river: 'Rio',
      escale: 'Registro Escala',
      escale_init: 'Escala ini.',
      evaporimeter: 'Evaporímetro',
      climatological: 'Climatológico',
      telemetry: 'Telemetría',
      sediments: 'Sedimentos',
      water_quality: 'Calidad del agua',
      liquid_discharge: 'Descarga liq.',
      yes: 'Sí',
      no: 'No',
      city_state: 'Área municipal/Estado',
      longitude_latitude: 'Longitud/Latitud',
      longitude: 'Longitud',
      latitude: 'Latiud',
      country_state: 'Estado',
      country: 'País',
      reg_fail: 'Días/Meses-Error',
      no_local_data: 'Sin datos de la estación local (in situ)',
      no_sat_data: 'Sin datos satelitales',
      precipitation: 'Precipitación total (mm)/mes',
      prec_details: 'Repositorio ANA',
      prec_details2: 'Satélites GPM',
      prec_overall: 'Promedio de años',
      prec_satOnly: '¡No hay datos de precipitación in situ!',
      prec: 'Precipitación',
      daily: 'Diario',
      about_imerge: 'Acerca de IMERGE',
      monthly: 'Mensual',
      name: 'Nombre',
      pluviometric: 'fluviométrico',
      fluviometric: 'Fluviométrique',
      basin_info: 'Información de la cuenca',
      Jan: 'Ene',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Abr',
      May: 'May',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Ago',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dez: 'Dic',
      year: 'Año',
      millimeters: 'mm',
      img: 'es',
      details: 'detalles',
      about_app: 'acerca de esta App',
      instructions: 'Instrucciones',
      init01:
        'Esta aplicación muestra series históricas de precipitación de la Red Hidrometeorológica Nacional (RNH) coordinada por la ANA (Agencia Nacional del Agua). Los datos de las estaciones pluviométricas de esta aplicación son operados por el SGB (Servicio Geológico Brasileño)',
      init02:
        '- Acceder a las estaciones pluviométricas de la Red Hidrometeorológica Nacional, haciendo clic en los círculos (Clusters) y los iconos de estaciones.',
      init03:
        '- Ver la precipitación mensual y diaria haciendo clic en las pestañas: Mensual y Diaria, al abrir el modal de estaciones.',
      init04:
        "- La precipitación promedio mensual en milímetros en la estación se puede comparar con la precipitación medida por satélite en el 'píxel' de 10 km2 de la estación. El promedio mensual in situ de la estación está en color violeta. En verde está la precipitación medida por satélite.",
      init05:
        '- El año de precipitación se puede seleccionar para in situ y satélite haciendo clic en la parte inferior del menú desplegable modal.',
      init06: '- El día del mes máximo está entre paréntesis en la pestaña Precipitación diaria.',
      app_libraries: 'Bibliotecas de código abierto en esta aplicación',
      option_language: 'Language',
      option_update: 'Update',
      application_update: 'Application Update',
      profile: 'Profile',
      user_details: 'User Details',
      app_settings: 'MapHidro Settings',
      home: 'Home',
      map: 'Map',
      about: 'About',
      uxsurvey: 'UX(Survey)',
      logout: 'Logout',
      projects: 'Projects',
      timeline: 'Timeline',
      download: 'Download',
      select: 'Select',
      filters: 'Filters',
      results: 'Results',
      help: 'Help',
      settings: 'Settings',
      select_layers: 'Select Layers',
      map_layers: 'Map Layers',
      brazil_basins: 'Brazil Basins',
      main_rivers: 'Main Rivers',
      samerica_basins: 'S. America Basins',
      heatmap: 'Heatmap',
      maphidro_help: 'MapHidro Help',
      usage_instructions: 'Usage Instructions',
      download_data: 'Download Data',
      data_patern: 'Data Patern',
      satelite_data: 'Satellite Data',
      about_json: 'About JSON Format',
      language: 'Language',
      uxpage_title: 'Questionário de UX',
      where: 'where',
      when: 'when',
      how: 'how',
      about_maphidro: 'About MapHidro',
      copyrights: 'All rights reserved',
      submit: 'Submit',
      about1:
        'This application uses open source libraries to collect and display calibrated satellite data and compare them with in situ rainfall stations operated by CRPM(Geological Survey of Brazil) from the National Hydrometeorological Network (RHN) coordinated by ANA (National Water and Sanitation Agency) in Brazil. Shows 20-year satellite precipitation measurements of more than 1500 pluviometric points of interest in Brazil',
      about2:
        'The Satellite precipitation data is provided by the Center for Weather Forecasting and Climate Studies (CPTEC), a division of the National Institute for Space Research (INPE). The MERGE product has been generated using the Global Precipitation Measurement (GPM) Integrated Multi-satellite Retrievals for GPM (IMERG) data. More details on the technique can be found at',
      about3:
        "NASA's Global Precipitation Measurement Mission (GPM) uses several satellites to measure Earth's rain and snowfall for the benefit of humanity. GPM is an international mission launched by NASA and JAXA(Japan) that sets the standard for measurements of precipitation in space.",
      indevelopment: 'This application is under development',
      uab_text:
        'Dissertation project for MSc in Informatics Engineering and Web Technology UAB/UTAD',
      search_for: 'Search for stations',
      search: 'search...',
      mapaccess: 'Acesse o mapa clicando aqui',
      series: 'Historical Series - Precipitation (mm)',
      select_years: 'Select the year of precipitation below',
      click_download: 'Click on the buttons to download data',
      thanks_response: 'Gracias por su respuesta',
      survey_text01:
        'Por favor danos tu opinión, para poder evaluar el portal maphidro.com, llenando el siguiente cuestionario a continuación. Está formado por pares de opuestos relativos a las propiedades que pueda tener el producto. Las graduaciones entre opuestos están representadas por círculos. Marcando uno de los círculos, podrás expresar tu opinión sobre un concepto. El cuestionario es anónimo, no hay respuestas "correctas" o "incorrectas". ¡Solo tu opinión personal cuenta!',
      signin: 'INICIAR SESIÓN',
      signup: 'Regístrate en MapHidro',
      username: 'Nombre de usuario',
      email: 'Correo electrónico',
      password: 'Contraseña',
      register_access: 'Regístrese para acceder',
      home_text_01:
        'Proyecto de tesis de Maestría en Ingeniería Informática y Tecnologías Web UAB/UTAD',
      home_text_02: 'MapHidro: Serie Histórica Precipitación in situ/satélite',
      create_username: 'Crea tu nombre de usuario',
      create_password: 'Crear una contraseña',
      email_placeholder: 'tu@ejemplo.com',
      user_alreadyregistered: 'Ese nombre de usuario ya se encuentra en uso',
      valid_email: 'Tu debes proveer un correo valido',
      password_min: 'La contraseña debe ser al menos 5',
      please_register: 'Please register to access the map functionality.',
      visit_homepage: 'You can visit the this link to sign up: ',
      homepage: 'HomePage'
    }
  }
};
i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    resources,
    supportedLngs: ['en', 'es', 'fr', 'pt'],
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });
export default i18next;
