import React, { useState, useEffect } from 'react';
import Map from './components/Map/map';

import Axios from 'axios';
Axios.defaults.baseURL = 'https://maphidro.com';

import { useImmerReducer } from 'use-immer';
import StateContext from './StateContext';
import DispatchContext from './DispatchContext';
import useWindowDimensions from './utils/useWindowDimensions';
import Header from './components/pages/Header';
import Footer from './components/pages/Footer';
import About from './components/pages/About';
import Terms from './components/pages/Terms';
import HomeGuest from './components/pages/HomeGuest';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import CreatePost from './components/pages/CreatePost';
import ViewSinglePost from './components/pages/ViewSinglePost';
import FlashMessages from './components/pages/FlashMessages';
import NotFound from './components/pages/NotFound';
import MapGuest from './components/pages/MapGuest';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChartModal from './components/ChartModal/ChartModal';
import AppDrawer from './components/Drawer/AppDrawer';
import axios from 'axios';
import { BASE_URL, COLLECTION_NAME, appSettings } from './components/Utils/constants';
import './main.css';
import UX from './components/pages/Ux';
import UXGuest from './components/pages/UXGuest';
import { layerType } from './components/Utils/types';

interface MonthNames {
  name: string;
}

const mapLayers: layerType[] = [
  { layerId: 'bacias', name: 'Brazil Basins', checked: false, added: false },
  { layerId: 'rivers', name: 'Main Rivers', checked: false, added: false },
  { layerId: 'bacias2', name: 'S. America Basins', checked: false, added: false },
  { layerId: 'clusters', name: 'Clusters', checked: true, added: true },
  { layerId: 'heatmapRain', name: 'Heatmap', checked: false, added: false }
];

const App: React.FC = () => {
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();

  const monthNames: MonthNames[] = [
    { name: t('Jan') },
    { name: t('Fev') },
    { name: t('Mar') },
    { name: t('Apr') },
    { name: t('May') },
    { name: t('Jun') },
    { name: t('Jul') },
    { name: t('Aug') },
    { name: t('Sep') },
    { name: t('Oct') },
    { name: t('Nov') },
    { name: t('Dec') }
  ];
  const initialState = {
    mapRef: null,
    stationData: {},
    localData: {},
    satData: {},
    dataSatLoaded: false,
    dataLocLoaded: false,
    barChart: {
      yearSelectSatData: 0,
      yearSelectLocData: 0,
      locBar: {},
      satbar: {},
      chartData: monthNames,
      satFirsYear: '',
      satLastYear: '',
      satBarSelectedYear: '',
      locLastYear: '',
      locFirstYear: '',
      locBarSelectedYear: ''
    },
    chartData: null,
    satHeatmap: {},
    locHeatmap: {},
    loggedIn: Boolean(localStorage.getItem('mapHidroToken')),
    flashMessages: [],
    user: {
      token: localStorage.getItem('mapHidroToken'),
      username: localStorage.getItem('mapHidroUsername'),
      avatar: localStorage.getItem('mapHidroAvatar'),
      email: localStorage.getItem('mapHidroEmail')
    },
    isSearchOpen: false,
    isChatOpen: false,
    unreadChatCount: 0,
    stationAction: false,
    modals: {
      panelBox: false,
      projects: false,
      timeline: false,
      download: false,
      where: false,
      when: false,
      how: false,
      select: false,
      filters: false,
      results: false,
      search: false,
      heatmapControls: false,
      centerModal: false,
      mobile: false,
      helpdialog: false,
      settingsdialog: false,
      selectdialog: false,
      station: false,
      stationdialog: false,
      scrollModal: false,
      initModal: true
    },
    header: {
      counterSelect: 1,
      counterFilter: 0,
      counterResults: 0,
      counterProjects: 0,
      counterTimeline: 0,
      counterDownload: 0
    },
    searchValue: '',
    searchData: '',
    searchResult: false,
    searchType: '',
    searchByName: true,
    searchByBasin: false,
    searchByUF: false,
    searchByRiver: false,
    searchBySat: false,
    mapLayers: mapLayers,
    heatmapControls: false,
    drawer: false,
    backdrop: false,
    overlay: false
  };

  /*Goal that all of our loading and saving user related actions takes place in one place
now have the user object that will be available in our global or app wide state.
So now any other component that needs to access this data will no longer need to talk
to the browser's localStorage. It can just find these values in State. 
But we need to ask ourselves how should these values get set into localStorage 
in the first place when you perform a successful login?
Well, we would also want to perform that within our Main.js file.
we first need to actually save these values into localStorage in the first place when you log in
because remember we just commented out that code within HeaderLoggedOut. Now, technically yes, 
we could just include code right here(Reducer Function) in this case that saves it into localStorage. 
But philosophically, we want to keep our Reducer pure in terms of it only working
with React ish things or only working with State. In other words if we need to do 
something that's considered a side effect like directly changing the browser's dom
in rare situations, or in this case working with the browser's local storage
we should probably do those types of things within a useEffect.
  */

  function mapReducer(draft: any, action: any) {
    switch (action.type) {
      case 'toggleStationModal':
        draft.modals.station = action.value;
        break;
      case 'setMapref':
        draft.mapRef = action.value;
        break;
      case 'login':
        draft.loggedIn = true;
        draft.user = action.data;
        break;
      case 'logout':
        draft.loggedIn = false;
        break;
      case 'flashMessages':
        draft.flashMessages.push(action.value);
        break;
      case 'loadStation':
        draft.stationData = action.value;
        break;
      case 'loadLocal':
        draft.localData = action.value;
        draft.dataLocLoaded = action.loaded;
        break;
      case 'loadSat':
        draft.satData = action.value;
        draft.dataSatLoaded = action.loaded;
        break;
      case 'loadData':
        draft.stationData = action.infoValue;
        draft.satData = action.satValue;
        draft.localData = action.locValue;
        draft.dataSatLoaded = action.locDataloaded;
        draft.dataLocLoaded = action.satDataloaded;
        draft.barChart.locLastYear = action.valueLocLastYear;
        draft.barChart.locFirstYear = action.valueLocFirstYear;
        draft.barChart.satFirstYear = action.valueSatFirstYear;
        draft.barChart.satLastYear = action.valueSatLastYear;
        draft.barChart.locBarSelectedYear = action.valueSelecetedLocBar;
        draft.barChart.satBarSelectedYear = action.valueSelecetedSatBar;
        draft.stationLoaded = action.stationLoaded;
        break;
      case 'chart':
        draft.barChart.locBar = action.locBar;
        draft.barChart.chartData = action.locBar;
        break;
      case 'setChart':
        draft.barChart.chartData = action.valueChart;
        break;
      case 'satHeatmap':
        draft.satHeatmap = action.valueSatHeatmap;
        break;
      case 'locHeatmap':
        draft.locHeatmap = action.valueLocHeatmap;
        break;
      case 'setLocBarSelectedYear':
        draft.barChart.locBarSelectedYear = action.valueSelecetedLocBar;
        break;
      case 'setSatBarSelectedYear':
        draft.barChart.satBarSelectedYear = action.valueSelecetedSatBar;
        break;
      case 'togleTimeLineModal':
        draft.modals.centerModal = true;
        draft.modals.search = false;

        draft.modals.timeline = true;
        break;
      case 'closeTimeLineModal':
        draft.modals.timeline = false;
        break;
      case 'togleProjectsModal':
        draft.modals.projects = true;
        break;
      case 'closeProjectsModal':
        draft.modals.projects = false;
        break;
      case 'togleSelectModal':
        draft.modals.select = action.value;
        break;
      case 'closeSelectModal':
        draft.modals.select = false;
        break;
      case 'toglePanelModal':
        draft.modals.panelBox = action.value;
        break;
      case 'toggleSearchModal':
        draft.modals.timeline = false;
        draft.modals.search = true;
        break;
      case 'closeSearchModal':
        draft.modals.search = false;
        break;
      case 'searchAction':
        draft.searchValue = action.searchEventValue;
        break;
      case 'searchDataAction':
        draft.searchData = action.searchDataValue;
        draft.searchResult = true;
        break;
      case 'toggleSearchByName':
        draft.searchByName = action.value;
        break;
      case 'toggleSearchByBasin':
        draft.searchByBasin = action.value;
        break;
      case 'toggleSearchByUF':
        draft.searchByUF = action.value;
        break;
      case 'toggleSearchByRiver':
        draft.searchByRiver = action.value;
        break;
      case 'togleHowModal':
        draft.modals.how = action.value;
        break;
      case 'setMapLayers':
        draft.mapLayers = action.value;
        break;
      case 'counterSelect':
        draft.header.counterSelect = action.value;
        break;
      case 'togleHeatmapControl':
        draft.modals.heatmapControls = action.value;
        draft.modals.how = false;
        break;
      case 'closeHeatmapControl':
        draft.mapLayers[4].checked = false;
        break;
      case 'toggleDrawer':
        draft.drawer = action.value;
        break;
      case 'toggleBackdrop':
        draft.backdrop = action.value;
        break;
      case 'toggleOverlay':
        draft.overlay = action.value;
        break;
      case 'toggleMobileModal':
        draft.modals.mobile = action.value;
        break;
      case 'toggleHelpDialog':
        draft.modals.helpdialog = action.value;
        break;
      case 'toggleSettingsDialog':
        draft.modals.settingsdialog = action.value;
        break;
      case 'toggleSelectDialog':
        draft.modals.selectdialog = action.value;
        break;
      case 'toggleStationDialog':
        draft.modals.stationdialog = action.value;
        break;
      case 'toggleInitModal':
        draft.modals.initModal = action.value;
        break;
      case 'toggleScrollModal':
        draft.modals.scrollModal = action.value;
        break;
    }
  }
  /*
    Immer gives us a copy of state. A perfectly cloned copy that we are free to modify and change however we want.
    And then Immer will automatically handle the task of giving that draft object back to React.
   */
  const [state, dispatch] = useImmerReducer(mapReducer, initialState);

  // when state.loggedIn change from dispatch in several places fires useEffect

  useEffect(() => {
    /*
    If that was just set and now it's true,
    then this is where we would want to save data
    into localStorage.
    */
    if (state.loggedIn) {
      localStorage.setItem('mapHidroToken', state.user.token!);
      localStorage.setItem('mapHidroUsername', state.user.username!);
      localStorage.setItem('mapHidroAvatar', state.user.avatar!);
      localStorage.setItem('mapHidroEmail', state.user.email!);
    } else {
      /*
      Else otherwise, well, if it just got set to false
      that means you just logged out.
      So this is where we can delete
      or remove that data from localStorage.
      */
      localStorage.removeItem('mapHidroToken');
      localStorage.removeItem('mapHidroUsername');
      localStorage.removeItem('mapHidroAvatar');
      localStorage.removeItem('mapHidroEmail');
    }
  }, [state.loggedIn]);

  useEffect(() => {
    if (state.searchValue) {
      search(state.searchValue);
    }
    /*
    If that was just set and now it's true,
    then this is where we would want to save data
    into localStorage.
    */
  }, [state.searchValue]);

  const search = async (val: string) => {
    const res = await axios(
      `${BASE_URL}/${COLLECTION_NAME}?filters[stName][$contains]=${val.toUpperCase()}`
    );
    console.log(res.data.data);
    dispatch({
      type: 'toggleBackdrop',
      value: !state.backdrop
    });
    if (res.data.data.length > 0) {
      dispatch({
        type: 'searchDataAction',
        searchDataValue: res.data.data
      });
      if (width < appSettings.mobileBreakpoint) {
        dispatch({
          type: 'toggleMobileModal',
          value: true
        });
        console.log('search');
      } else {
        dispatch({ type: 'toggleSearchModal' });
      }
    }
  };

  const [loading, setLoading] = useState(true);
  const [offCanvas, setOffCanvas] = useState(false);

  const handleMapLoading = () => setLoading(false);

  /*
  Well, when our main component first renders we would just immediately wanna send an Axios request
  to the server to check if our token is still valid or not. If it's no longer valid, if the server says
  that the token is too old, we can just force the user to log out and have them log back in
  so they will have a new perfectly working token.
  */
  useEffect(() => {
    if (state.loggedIn) {
      const ourRequest = Axios.CancelToken.source();
      const fResults = async function fetchResults() {
        try {
          const response = await Axios.post(
            '/checkToken',
            { token: state.user.token },
            { cancelToken: ourRequest.token }
          );
          if (!response.data) {
            dispatch({ type: 'logout' });
            dispatch({
              type: 'flashMessages',
              value: 'Your session has expired. Please log in again.'
            });
          }
        } catch (e) {
          console.log('There was a problem or the request was cancelled.');
        }
      };
      fResults();
      return () => ourRequest.cancel();
    }
  }, []);

  /*
    remove modalPanel after click on close
  */
  useEffect(() => {
    if (state.modals.projects == false && state.modals.select == false) {
      dispatch({ type: 'toglePanelModal', value: false });
    }
  }, [state.modals.projects, state.modals.select]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <div className="main">
            <Header />
            <FlashMessages messages={state.flashMessages} />
            <Routes>
              <Route path="/" element={state.loggedIn ? <Home /> : <HomeGuest />} />
              <Route
                path="/map"
                element={
                  state.loggedIn ? (
                    <Map
                      initialOptions={{ center: [-55.59, -15.91], zoom: 4.1 }}
                      onLoaded={handleMapLoading}
                      offCanvas={offCanvas}
                      setOffCanvas={setOffCanvas}
                    />
                  ) : (
                    <MapGuest />
                  )
                }
              />
              <Route path="/post/:id" element={<ViewSinglePost />} />
              <Route path="/create-post" element={<CreatePost />} />
              <Route path="/about-maphidro" element={<About />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/ux" element={state.loggedIn ? <UX /> : <UXGuest />} />
              <Route path="*" element={<NotFound />} />
            </Routes>

            <Footer />
            <AppDrawer />
            {state.modals.station && (
              <ChartModal
                //stationObj={stationObj}
                show={state.modals.station}
                onHide={() =>
                  dispatch({
                    type: 'toggleStationModal',
                    value: false
                  })
                }
              />
            )}
          </div>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
};

export default App;
